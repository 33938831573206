
.woocommerce-breadcrumb {
  font-family:  @fontHeadline;
  padding:      0.5rem;

}

.woocommerce-message {
  margin:   1rem 0;
  box-shadow: @shadowCard;
  background: shade(@paletteBackingCard, 5%);

  .wc-forward {
    background:       @paletteMpkAccent;
    font-family:      @fontHeadline;
    color:            @paletteReverseInk;
    padding:          0.5rem;
    margin-right:     0.5rem;
    font-weight:      bold;
    text-decoration:  none;
    display:          inline-block;


  }
}



.products {
  .reset-list-styles;
  margin-left: -1%;
  margin-right: -1%;

  .product {
    width:           31%;
    margin:          1em 1%;
    display:         inline-block;
    vertical-align:  top;
    position:        relative;
    background:  @paletteMpkAccent;

    a {
      display:     block;
      color:       @paletteReverseInk;
      text-decoration:  none;
    }

    img {
      width: 100%;
    }

    h3 {
      text-shadow:  @shadowText;
      font-size:    rem(24);
      margin:       8px 16px 4px;
      text-align:   left;
    }

    .price {
      display:     inline-block;
      text-align:  left;
      margin:      4px 16px 8px;
      font-family:  @fontHeadline;
    }

    .button.product_type_simple {
      padding:      4px 16px 8px;
      font-family:  @fontHeadline;

    }

    a.add_to_cart_button {
      font-family:  @fontHeadline;
      position:     absolute;
      right:        4px;
      bottom:       4px;
      padding:      4px;
      background:   @paletteMpkStrong;
    }
  }


}

.product.type-product {
  .clear();
  margin-bottom: 3em;

  .images {
    float:         left;
    position:      relative;
    z-index:       200;
    max-width:     360px;

    .from(600, {
      margin-right:  1em;
    });
    
    a {
      display: block;
    }

    img {
      max-width:     100%;
    }
  }

  .summary {
    position:     relative;
    margin-top:   3em;
    padding-top:  1px;
    min-height:   12rem;

    .product_title {
      background:     @paletteMpkAccent;
      color:          @paletteReverseInk;
      margin-top:     0.25em;
      margin-bottom:  1rem;
      padding:        0.25em 0.25em 0.125em;
    }

    .price {
      float:        right;
      padding:      0.5rem 0rem;
      font-size:    3em;
      font-weight:  bold;
      margin:       0;
      font-family:  @fontHeadline;
      width:        200px;
      z-index:      200;
      text-align:   center;
    }

    .cart {
      float:       right;
      width:       200px;
      clear:       right;
      margin-top:  20px;
      padding:     0.5em;

      box-shadow:  @shadowCard;
      background:  @paletteBackingCard;

      // input[type="button"] {
      //   background:   @paletteMpkStrong;
      //   text-shadow:  none;
      //   color:        @paletteReverseInk;
      //   font-family:  @fontHeadline;
      //   border:       none;
      //   text-shadow:  none;
      //   display:      inline-block;
      //   font-size:    rem(16);
      //   width:        1.35em;
      //   line-height:  1.35em;
      //   font-weight:  bold;
      // }

      .qty {
        line-height:    1.35em;
        border-radius:  none;
      }

      .variations {
        td {
          padding:         3px;
          vertical-align:  top;
        }
      }

      .minus, .qty, .plus {
        display:  none;
      }

      .single_add_to_cart_button {
        background:   @paletteMpkStrong;
        text-shadow:  none;
        color:        @paletteReverseInk;
        font-family:  @fontHeadline;
        border:       none;
        text-shadow:  none;
        display:      block;
        width:        100%;
        font-size: rem(20);
        line-height:  1.35em;
        font-weight:  bold;
      }
    }
  }

}


.woocommerce {
  .shop_table {
    box-shadow: @shadowCard;

    thead {
      background:  @paletteMpkAccent;
      color:       @paletteReverseInk;
      font-family:  @fontHeadline;
    }

    tbody {
      background: @paletteBackingCard;
    }
  }

  .cart-collaterals .cart_totals {
    h2 {
      margin: 0;
      font-size: rem(32);
    }
    table {
      float:      right;
      max-width:  400px;
      text-align:  right;
      tr th {
        width:       auto;
        text-align:  right;
      }
    }
  }

  .shipping_calculator {
    box-shadow: @shadowCard;
    background: @paletteBackingCard;

    h2 {
      margin: 0;
      font-size: rem(24);
      background:  @paletteMpkAccent;
      text-align:       center;
      
      a {
        color:            @paletteReverseInk;
        text-decoration:  none;

        &:after {
          display: none;

        }
      }
    }

    .shipping-calculator-form {
      padding: 0.5em;

      p:last-child {
        margin-bottom:  0;
      }
    }
  }

  .shipping-calculator-form .button,
  .cart input.button {
    background:   @paletteMpkStrong;
    text-shadow:  none;
    color:        @paletteReverseInk;
    font-family:  @fontHeadline;
    border:       none;
    text-shadow:  none;

    .targeted({
      background:   @paletteMpkStrong;
      color:        @paletteReverseInk;
    });
    

    &.alt {
      background:  @paletteMpkAccent;
      .targeted({
        background:  @paletteMpkAccent;
      });
    }
  }

}

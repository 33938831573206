.mapEmbed {
	display: none;
	float: right;
	height: 400px;
	width: 40%;
	padding: 0;	

	border: 1px solid @paletteMpkDarkBase;
	box-shadow: @shadowCard;

	.from(@mediaSecondaryMenuBreak, { 
		display: inline-block;
	});
}

.location {
	padding: 0.8rem;
	.clear;
	
	&__details {	
		margin: 0;
		.from(@mediaSecondaryMenuBreak, { 
			float: left;
			width: 59%;
		});
	}

	&__title {
		display: inline-block;
		margin: 0;
		width: 100%;

        font-size: @sizeLargeHeading;
        border-bottom: 1px solid black;
	}

	&__subTitle {
		font-size: @sizeMidHeading;
		font-style: italic;
		margin: 0.5rem 0;
	}

	&__link {
		padding: 0 0.5rem;
		font-family: @fontHeadline;
		font-size: @sizeBase;
		font-style: italic;
		font-weight: normal;
		text-decoration: none;
	}

	&__img {
		display: block;
		width: 80%;
		height: auto;
		margin: 1.5rem 10%;
		border: 1px solid @paletteMpkDarkBase;
		box-shadow: @shadowCard;
	}
}
.calloutButton {
  .from(@mediaSecondaryMenuBreak, {
    float:        left;
    width:        45.5%;
    margin-left:  3%;
    min-height:   14rem;
  }); 

  .from((@mediaSecondaryMenuBreak + 30), {
    min-height:   13rem;
  });

  display:     block;
  position:    relative;
  margin:      1rem 0;
  padding:     0.5rem 0;
  min-height:  170px;

  background: @paletteMpkDarkBase; 
  background-size: cover;
  box-shadow: @shadowCard;
  text-decoration: none;
  @leftStripeWidth: 110px;

  &__redStripe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: @leftStripeWidth;
    
    background: @paletteMpkAccent;
  }

  &__title {
    position:     relative;
    margin:       0.5rem 1rem;
    line-height:  1em;
  
    font-size: @sizeLargeHeading;
    color: @paletteReverseInk;
    text-shadow: @shadowText;
  }

  &__content {
    margin-bottom: 30px;
    padding:  10px 15px;
    padding-left: (@leftStripeWidth + 15px);
    
    background: rgba(0,0,0,0.65);
    color: @paletteReverseInk;

    *:first-child {
      margin-top: 0;
    }
  }

  &__more {
    display: block;
    
    text-align: right;
    text-transform: lowercase;
    color:  tint(@paletteMpkAccent, 40%);
  }
}

.aside {	

	.from(@mediaSecondaryMenuBreak, {
		float: right;
    width: 40%;
		margin-bottom: 2rem;
		padding: 0.5rem;
		position: relative;
		z-index: @zindexMid;

		&--raise {
			margin-top: -4rem;
		}

		&--largeWidth {
			width: 65%;
		}

		&--shortWidth {
			width: 40%;
		}
	})
}

.faqList {
	.reset-list-styles;
	margin: 1rem 0.5rem;

	.from(@mediaSecondaryMenuBreak, {
		max-width: 70%;
	})
}

.faqItem {
	&__title {
		margin: 0.8rem 0;
	}
	&__link {
		margin: 0.5rem 0;
		font-size: @sizeMidHeading;
		font-weight: normal;

		&:hover {
			text-decoration: underline;
		}
	}

	&__block {
		line-height: 1rem;
		display: none;
		padding: 0.5rem 0;

		font-size: @sizeContent;
		line-height: 1.4rem;

		*:first-child { margin-top: 0; }
		*:last-child { margin-bottom: 0; }
	}
}
.frontPageUpcomming {
    &__title {
        display: inline-block;
        margin: 0 0.5rem 0 0;
        font-size: @sizeLargeHeading;
    }

    &__link {
        font-family:      @fontHeadline;
        font-size:        @sizeSmallHeading;
        text-decoration:  none;
        text-transform:   lowercase;
    }
    
    &__monthTitle {
        margin: 5px 0 2px;
        padding: 0;
        
        text-transform: uppercase;
        font-weight: normal;
        font-size: @sizeMidHeading;
        color: @paletteMpkDarkBase;
    }
    
    &__list {
        .reset-list-styles;
        padding:0;
        margin:0;
    }
}
.upcomingItem {
    margin-bottom: 5px;

    &__calendarDay {
        float: left;
    }

    &__events {
        .reset-list-styles;
    }
}

.calendarDay {
    display: block;
    width: 3em;
    min-height: 56px;

    background-color: @paletteMpkAccent;
    font-family: @fontHeadline;
    color: @paletteReverseInk;

    &__date, &__day {
        display: block;
        width: 100%;
        text-align: center;
    }
    &__date {
        padding-top: 0.4em;
        
        font-size: 21px;
        font-weight: bold;
    }

    &__day {
        padding-bottom: 0.3em; 
        line-height: 1.1em;       
    }
}

.upcomingEvent {
    &__summary {
        min-height: 56px;
        padding: 3px 5px 2px;
        margin-left: 42px;

        color: @paletteMpkDarkBase;
    }

    &__title {
        margin: 0;  
        padding: 0;
        
        font-weight: bold;
        line-height: 1.25em;
        font-size: @sizeMidHeading; 
        font-style: italic;  
    }

    &__time, &__location {
        font-weight: normal;
        line-height: 1em;
        font-family: @fontHeadline;
        font-style: italic;     
    }   
}

.frontPageCommunity {
    margin: 2rem 0;

    &__title {
        margin: 0 0.5rem 0.3rem 0;
        font-size: @sizeLargeHeading;
    }

    &__list {
        margin: 0;
        .reset-list-styles;
    }

    &__link {
        font-weight: normal;
        line-height: 1em;
        font-family: @fontHeadline;
        font-size: @sizeSmallHeading;
        text-decoration: none;
    }
}

.frontPageNews {
    &__title {
        display: inline-block;

        margin: 0;
        font-size: @sizeLargeHeading;

        line-height: 1.25em;

    }

    &__link {
        padding: 0 0.6rem;
        font-family: @fontHeadline;
        font-size:        @sizeSmallHeading;
        text-decoration: none;
        text-transform:   lowercase;
    }

    &__list {
        margin: 0;
        .reset-list-styles;
    }
}

.newsItem {
    &__postDate {
        margin: 0;
        font-size: @sizeBase;
        color: @paletteMpkAccent;
        font-family: @fontHeadline;   
    }

    &__block {
        :first-child {
            margin-top: 0;   
        } 
    }

    &__block\* {
        font-size: @sizeBase;
        p {
            margin: 0 0 1rem;
            line-height: 1.5;
        }

        h3 {
            margin: 0;
            font-size: @sizeSubHeading;
        }
    }
}

.frontPageContent {
    .clear;
    clear:             both;
    padding:           0.5rem 0;
    background-color:  @paletteBackingCard;
}

.frontPageIntro {
    margin: 0px 10% 1em;
    
    font-size: 21px;
    line-height: 1.5em;
    text-align: center;
}

@layer-float: 2000;

html {
  min-height:  100%;
}
body {
  margin:      0 auto;
  max-width:   920px;
  min-height:  100%;
}

@media only screen and (min-width: 760px) {
  body {
  background: white linear-gradient(to bottom, lighten(@paletteMpkStrong, 40),
                                         white 600px) no-repeat;

  }
}

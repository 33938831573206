.classTimes {
	background-color: @paletteBackingCard;
	box-shadow: @shadowCard;
	padding: 15px;
	
	&__title {
		text-transform: uppercase;
		font-size: @sizeLargeHeading;
		margin: 0;
	}

	.from(@mediaSecondaryMenuBreak, { 
		margin: 15px;
	});
}

.classTimesLocation {
	&__title {
		margin: 0;
		font-weight: normal;
		font-size: @sizeMidHeading;
	}

	&__content {
		margin:         0;
		margin-top:     2px;
    margin-bottom:  1em;

    &:last-child {
      margin-bottom:  0;
    }
	}
}


.eventFilter {
	&__subTitle, &__title {
		padding: 0 0.5rem;
		margin: 0.2rem 0 0.2rem;
	}
	
	&__title {
		font-size: @sizeLargeHeading;
	}

	&__subTitle {
		font-size: @sizeSubHeading;
		font-weight: normal;
	}

	&__list {
		margin: 0 0 0.7rem;

		.reset-list-styles;
	}
}

.filterItem {
	padding: 0.4rem 0.5rem 0;
	
	&:hover {
		background-color: tint(@paletteMpkAccent, 60%);
		cursor: pointer;
	}

	&.is-active {
		background-color: @paletteMpkAccent;
		color: @paletteReverseInk;
	}
	
	&__link {
		font-size: @sizeMidHeading;
		font-weight: normal;
		font-family: @fontHeadline;
	}

	&.is-active &__link {
		font-size: @sizeMidHeading;
		font-weight: normal;
		font-family: @fontHeadline;
		color: @paletteReverseInk;
	}
}

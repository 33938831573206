#_flow {
  font-family:  @fontBody;
  min-height:   315px;
  background: linear-gradient(to bottom, lighten(@paletteMpkStrong, 60), white);

  .flow-eventBody, .flow-action {
    border: none;
  }
  
  .flow-eventDetailPanel {
    background:  none;
    border:      none;
  }
  
  .flow-eventActionPanel {
      background:  none;
      border:      none;
  }
  
  .flow-confirmPanel {
    background:  none;
    border:      none;
  }
}


#_flow .flow-eventBack {
  display: none;
}
#_flow .flow-confirmControls {
  display: none;
}
#_flow .flow-eventBody {
  display: none;
}
#_flow .flow-eventHeader {
  display: none;
}

#_flow .flow-eventHeader {
  .flow-eventName {
    font-family:     @fontHeadline;
    text-transform:  uppercase;
    margin-top:      0.25em;
    font-size:       ms(3);
    line-height:     1em;
    margin-bottom:   -0.2em;
    font-style:      italic;
  }

  .flow-eventDate {
    font-family:     @fontHeadline;
    text-transform:  uppercase;
    margin-top:      0.25em;
    font-size:       ms(1);
    font-style:      italic;
  }
}  

#_flow .flow-eventStatusPanel {
  background: @paletteMpkStrong;
}

#_flow .flow-actionHeader, #_flow .flow-confirmHeader {
  font-family:     @fontHeadline;
  text-transform:  uppercase;
  font-weight:     bold;
  border:          none;
  font-size:       ms(3);
  line-height:     1.25em;
  background:      @paletteMpkAccent;
  color:           #FFF;
}

#_flow .flow-actionHint {
  background:     @paletteMpkAccent;
  font-family:    @fontHeadline;
  border-radius:  0;
  color:          #FFF;
  margin-left:    -0.5em;
  width:          2em;
  line-height:    2.4em;
  height:         2em;
}

#_flow .flow-actionControls, #_flow .flow-actionEmailPanel, #_flow .flow-actionInputGroup {

  button {
    font-family:      @fontHeadline;
    font-size:        1em;
    text-transform:   uppercase;
    padding-top:      0.5em;
    line-height:      1.05em;
    text-decoration:  none;
    font-weight:      bold;
    border-radius:    0;
    border:           none;
    padding-bottom:   0.25em;
    background:       @paletteMpkAccent;
    color:            #FFF;
    &:hover, &:focus {
      background:       lighten(@paletteMpkAccent, 10);
    }
    &:disabled {
      background: #777;
    }
  }
    
  button.flow-actionCancel {
    display:       none;
  }

  button.flow-actionEmailSubmit, button.flow-actionAdd {
    background:       @paletteMpkStrong;
    &:hover, &:focus {
      background:       lighten(@paletteMpkStrong, 10);
    }
  }

  button.flow-inputRemove {
    opacity:  0.8;
    width:    1.5em;
  }
}



.footer {
  background:  @paletteMpkDarkBase;
  padding:     0.6rem 0.5rem 1.25rem;
  color:       @paletteReverseInk;

  clear: both;

  &__byClassTypeList,
  &__byLocationList,
  &__navLinksList {
    margin: 3px 0px 8px;
    padding: 0;
    font-size: 0;
  }

  &__byClassTypeListElement,
  &__byLocationListElement,
  &__navLinksListElement {
    list-style-type: none;
    font-size: @sizeBase;
    padding: 0px 0;
  }

  &__byClassTypeLink,
  &__byLocationLink,
  &__navLinksLink {
    text-decoration: none;
    font-family: @fontHeadline;
    font-size: @sizeFooterLink;
    padding: 0px 0;
    line-height: 14px;
  }

  &__sectionTitle {
    margin: 3px 0;
    text-transform: uppercase;
    font-size: @sizeFooterSectionTitle;
  }

  &__listTitle {
    margin: 5px 0;
    font-weight: normal;
    font-size: @sizeFooterListTitle;
  }

  &__affiliation,
  &__copyright {
    font-family:    @fontHeadline;
    font-size:      @sizeBase;
    text-align:     right;
    margin-bottom:  0.75rem;
  }
 
  &__copyright {
    color:       @paletteDarkReverseInk;
    padding-top:  1.15rem;
    border-top:    1px solid 

  }


  .from(@mediaFooterBreak, {
    font-size: 0;

    &__section {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }
  });

  .from(@mediaFooterBreak, legacy, {
    padding: 0.6rem 1.25rem 1.25rem;
  });
}

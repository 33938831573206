.button {
    display: block;
    padding: 15px;

    background-color:  @paletteMpkAccent;
    color:             @paletteReverseInk;
    text-transform:    uppercase;
    text-align:        center;
    font-size:         @sizeMidHeading;
    font-weight:       bold;
    font-family:       @fontHeadline;
    box-shadow:        @shadowCard;
    text-decoration:   none;

    &:hover {
        background-color: shade(@paletteMpkAccent, 20%);
    }
}

.targeted(@rules) {
  &:hover, &:focus {
    @rules();
  }
}

.ratio-child-alt() {
  position:  absolute;
  top:       0;
  left:      0;
  right:     0;
  display:   block;
  height:    auto;
  // Use height: 100%; for video, iframe etc.
}

.ratio-update( @width: 16; @height: 9 ) {
	padding-bottom: (@height / @width * 100%); // default 16:9
}

.ellipse-overflow {
  text-overflow:  ellipsis;
  overflow:       hidden;
}

#no-js(@rules) {
  .mod\*--no-js & {
    @rules();
  }
}

.activated(@rules) {
  &:active {
    @rules();
  }
}

.positional-fill {
  top:     0;
  left:    0;
  right:   0;
  bottom:  0;
}

.reset-bounds {
  margin:   0;
  padding:  0;
}

.reset-list-styles {
  list-style:    none;
  padding-left:  0;
}

.reset-button {
  background:  transparent;
  border:      none;
}

.image-resizer {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  display: block;
}

.image-resizer-image {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
}

.vertical-center-margin {
  top:            0;
  position:       absolute;
  margin-top:     auto;
  margin-bottom:  auto;
  bottom:         0;
}

.absolute-centered-vertical {
  position:  absolute;
  margin:    auto;
  top:       0;
  bottom:    0;
}

.absolute-centered-horizontal {
  position:  absolute;
  margin:    auto;
  left:       0;
  right:    0;
}

.absolute-centered {
  position:  absolute;
  margin:    auto;
  left:       0;
  right:    0;
  top:       0;
  bottom:    0;
}

@fontHeadline: "Eurostile", sans-serif;
@fontBody: "Vani", "Georgia", "Times New Roman", serif;

@fontMonospace: "Bitstream Vera Sans Mono", "Andale Mono", "Lucida Console", monospace;
@sizeBase: 14px;
@sizeContent: 17px;
@sizeContentLineHeight: 23px;

@sizePriceBlockDollar: 2.5rem;
@sizePriceBlockUnits: 2.0rem;

@sizeLargeHeading: 2.1rem;
@sizeSubHeading: 1.7rem;
@sizeMidHeading: 1.4rem;
@sizeSmallHeading: 1.1rem;

@sizeSecondaryHeadingTitle: 1.2rem;
@sizeMainHeader: 2.8rem;

@sizeFooterSectionTitle: 1.35rem;
@sizeFooterListTitle: 1.2rem;
@sizeFooterLink: 1.1rem;

body {
  font-family:  @fontBody;
  font-size:    @sizeBase;
  line-height:  1.25em;
}

h1, h2, h3, h4, h5, h6 {
  line-height:  1.25em;
  font-family:  @fontHeadline;
}

h1, h2, h3 {
  text-transform:  uppercase;
  font-weight:     bold;
}

li {
  line-height: 1.6rem;
}

.h1,
h1 {
  font-size: rem(47);
}

.h2,
h2 {
  font-size: rem(38);
}


.h3,
h3 {
  font-size: rem(32);
}

.h4,
h4 {
  font-size: rem(21);
}

a {
  color: @paletteMpkAccent;
}

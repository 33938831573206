.contactForm {
  margin: 2rem 0;

  background-color: white;
  border: 1px solid @paletteMpkAccent;
  box-shadow: @shadowCard;

  clear: both;

  .from(@mediaSecondaryMenuBreak, { 
    max-width: 65%;
  });

  &__title {
    display: block;
    margin: 0;
    padding: 5px;

    font-weight: bold;
    font-size: @sizeMidHeading;
    color: @paletteReverseInk;
    text-align: center;
    background-color: @paletteMpkAccent;
    text-transform: uppercase;
  }

  &__row {
    padding: 10px 0;
  }

  &__label {
    display: inline-block;
    width: 30%;
    padding: 5px;

    font-family: @fontHeadline;
    text-align: right;
    font-weight: bold;
  }

  &__input,&__textarea {
    border: 1px solid black;
    width: 60%;
  }

  &__submit {
    display: block;
    color: ; 
    width: 100%;
    padding: 0.5em 2rem;
   
    text-transform: uppercase;
    text-align: right;
    font-family: "Eurostile",sans-serif;
    font-weight: bold;
    font-size: 21px;
    color: @paletteMpkAccent;
    border: medium none;
    background-color: transparent;

    &:hover {
      background-color: tint(@paletteMpkAccent, 75%)
    }
  }
}
.eventDay {
    margin-bottom: 5px;
   
    &__calendarDay {
        float: left;
    }
}
.event {
    &__summary {
        min-height: 56px;
        padding: 3px 5px 2px;
        margin-left: 42px;

        cursor: pointer;
        color: @paletteMpkDarkBase;

        &:hover {
            background-color: tint(@paletteMpkLightBase, 60%);
        }  

        &.is-active {
            background-color: @paletteMpkStrong;
            color: @paletteReverseInk;
        }

    }

    &__title, &__location {
        padding: 0;
        margin: 0;  
        font-size: @sizeMidHeading; 
        font-style: italic;                 
    }
    &__title {
        font-weight: bold;
        line-height: 1.25em;
    }

    &__titlePrice {
        font-size:    @sizeBase;
        margin-left:  0.5em;
        font-weight:  normal;
    }

    &__location {
        font-weight: normal;
        line-height: 1em;
    }

    &__additional {
        margin: 0 0 20px 42px;
    }

    &__extraClasses {
        padding-left: 8px;
        
        font-size: @sizeBase;
        color: @paletteMpkDarkBase;
    }

    &__iframe {
      float:          right;
      width:          100%;
      height:         340px;
      margin-bottom:  2rem;

      .from(500, {
        min-width:  500px;
      });

      border:     1px solid @paletteMpkAccent;
    }
}

.calendarDay {
    display: block;
    width: 3em;
    min-height: 56px;

    background-color: @paletteMpkAccent;
    font-family: @fontHeadline;
    color: @paletteReverseInk;

    &__date, &__day {
        display: block;
        width: 100%;
        text-align: center;
    }
    &__date {
        padding-top: 0.4em;
        
        font-size: 21px;
        font-weight: bold;
    }

    &__day {
        padding-bottom: 0.3em; 
        line-height: 1.1em;       
    }
}

.eventList {
    &__bookingTip {
        padding: 20px 0 15px;

        background-color: @paletteMpkLightBackingPrice;
        text-align: center;
        font-size: @sizeSecondaryHeadingTitle;   
    }
    
    &__monthTitle {
        margin: 5px 0 2px;
        padding: 0;
        
        text-transform: uppercase;
        font-weight: bold;
        font-size: @sizeLargeHeading;
        color: @paletteMpkDarkBase;
    }
    
    &__events {
        .reset-list-styles;
        padding:0;
        margin:0;
    }
}

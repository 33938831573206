.headergroup {
    width: 100%;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
	color: @paletteMpkDarkBase;
	
    &__title {
        margin-bottom: 0em;
        font-size: @sizeMainHeader;
        font-weight: bold;

    }

    &__subtitle {
        margin: 5px 0;
        line-height: 0.85em;
        font-size: 38px;
    }

    &__text {
        margin: 0;
        font-size: @sizeContent;

    }
}
@font-face {
  font-family:  'Eurostile';
  src:          url('./fonts/267C24_0_0.eot');
  src:          url('./fonts/267C24_0_0.eot?#iefix') format('embedded-opentype');
  src:          url('./fonts/267C24_0_0.woff') font('woff');
  src:          url('./fonts/267C24_0_0.ttf') format('truetype');
  font-weight:  normal;
  font-style:   normal;
}

@font-face {
  font-family:  'Eurostile';
  src:          url('./fonts/267C24_1_0.eot');
  src:          url('./fonts/267C24_1_0.eot?#iefix') format('embedded-opentype');
  src:          url('./fonts/267C24_1_0.woff') font('woff');
  src:          url('./fonts/267C24_1_0.ttf') format('truetype');
  font-weight:  normal;
  font-style:   italic;
}

@font-face {
  font-family:  'Eurostile';
  src:          url('./fonts/267C24_2_0.eot');
  src:          url('./fonts/267C24_2_0.eot?#iefix') format('embedded-opentype');
  src:          url('./fonts/267C24_2_0.woff') font('woff');
  src:          url('./fonts/267C24_2_0.ttf') format('truetype');
  font-weight:  bold;
  font-style:   normal;
}

@font-face {
  font-family:  'Eurostile';
  src:          url('./fonts/267C24_3_0.eot');
  src:          url('./fonts/267C24_3_0.eot?#iefix') format('embedded-opentype');
  src:          url('./fonts/267C24_3_0.woff') font('woff');
  src:          url('./fonts/267C24_3_0.ttf') format('truetype');
  font-weight:  bold;
  font-style:   italic;
}

.secondaryHeader {
  background-color: @paletteMpkDarkBase;
  font-family: @fontHeadline;
  box-shadow: @shadowCard;
  z-index: @zindexTop;

  &--fixed {
    position: fixed;
    top: 0;
    max-width: 921px;
    width: 100%;
  }
  &__title {
    color: @paletteReverseInk;
    font-size: @sizeSecondaryHeadingTitle;
    text-transform: uppercase;
    text-shadow: @shadowText;
    margin: 0px;
  }
  &__navList {
    list-style-type: none;
    padding: 0;
    font-size: 0;
  }
  &__navListElement {
    font-size:  14px;
    display:    inline-block;
    position:   relative;
  }
  &__navLink {
    padding: 5px 8px;
    text-decoration: none;
    color: @paletteReverseInk;
    
    &.is-active {
      background-color: @paletteReverseInk;
      color: @paletteMpkDarkBase;
    }

    .targeted({
      background-color: @paletteReverseInk;
      color: @paletteMpkDarkBase;
    });
  }

  &__navSub {
    &:extend(.reset-list-styles);

    display:   none;
    position:  absolute;
    top:       100%;
    left:      0;
    z-index:   @layer-float;

    background:  shade(@paletteMpkDarkBase, 10%);
    box-shadow: @shadowCard;
  }

  &__navSubListElement {
    display:      block;
    line-height:  1;
  }

  &__navSubLink {
    padding:      0.45rem 0.5rem 0.45rem;
    display:     block;
    white-space:  nowrap;

    font-size:        @sizeBase;
    text-decoration:  none;
    color:            @paletteReverseInk;

    .targeted({
      background:  @paletteReverseInk;
      color:       @paletteMpkDarkBase;
    });
  }

  &__navListElement:hover &__navSub {
    display: block;
  }

  .from(@mediaSecondaryMenuBreak, {  
    padding: 8px 3px 2px 10px;
    margin: 0px;
    &__title {
      display: inline-block;
    }
    &__nav {
      display: inline-block;
      float: right;      
    }
    &__navList {
      margin: 0px;
    }
  });//end secondaryHeader from media query

  .to(@mediaSecondaryMenuBreak, {
    padding: 8px 0 5px;
    margin: 0px;
    &__title {
      text-align: center;
    }
    &__navList {
      margin: 8px 0 3px;
      text-align: center;
    }
  });//end secondaryHeader to media query
}//end 'secondaryHeader'

.secondaryHeaderImage {
  background-position: center top; 
  background-repeat: no-repeat;
  height: 75px;
  width: 100%;    

  .to(@mediaSecondaryMenuBreak, {
    display: none; 
  });
}//end 'secondaryHeaderImage'

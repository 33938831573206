@import (inline) "./css/normalize.css";
// @import (inline) "./css/style.css";


@import (reference) "../node_modules/toolkit-less-css/toolkit.less";

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

@import "./utilities/shorthand.less";
@import "./foundation/fonts.less";
@import "./foundation/layout.less";
@import "./foundation/palette.less";
@import "./foundation/typography.less";
@import "./foundation/mediaQueries.less";
@import "./foundation/zindex.less";

@import "./atoms/supportLink.less";

@import "./components/header.less";
@import "./components/woocommerce.less";
@import "./components/footer.less";
@import "./components/classTimes.less";
@import "./components/secondaryHeader.less";
@import "./components/headergroup.less";
@import "./components/imgCarousel.less";
@import "./components/eventList.less";
@import "./components/priceBlock.less";
@import "./components/content.less";
@import "./components/aside.less";
@import "./components/contactForm.less";
@import "./components/faqList.less";
@import "./components/eventFilter.less";
@import "./components/button.less";
@import "./components/calloutButton.less";
@import "./components/frontPage.less";
@import "./components/location.less";
@import "./components/booking-frame.less";

@import "./contexts/singleClass.less";

.imgCarousel {
	overflow: hidden;
	box-shadow: @shadowCard;

	.ratio(16, 4);
    
    &__imgList{
    	.ratio-child;
    	.reset-list-styles;
    	margin: 0;
    }

    &__imgListEle {
		position: absolute;
		top: 0;
		width: 100%;	
    }

    &__imgListImg {
 		width: 100%;
 		opacity: 1;
 		transition: opacity 0.5s linear;
   	
   	    &.is-hidden {
    		opacity: 0;
    	}
    }

    &__pipList {
    	.reset-list-styles;
    	margin: 0;
    	position: absolute;
    	top: 20px;
    	right: 25px;
    }

	&__pipListEle {
		display: inline-block;
	}

	&__pip {
		display: inline-block;
		width: 1em;
		height: 1em;
		line-height: 1em;
		
		border: 1px solid gray;
		background-color: white;

		&.is-active{
			background-color: gray;
		}
	}
}
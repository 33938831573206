.priceBlock {
	display: block;
	margin: 2.5em 0 1.5em;

	font-family: @fontHeadline;
	color: @paletteMpkDarkBase;
	font-weight: bold;
	text-align: center;
	
	&__dollars {
		font-size: @sizePriceBlockDollar;
	}

	&__perClass {
		font-size: @sizePriceBlockUnits;
	}
}
.header {
  background:  @paletteMpkStrong;
  position:    relative;
  padding:     1rem;
  top: 0;

  .header &__imgCarousel {
    position: absolute;
    .positional-fill;
    padding: 0;
    margin: 0;
  }

  &__title {
    position: relative;
    margin:      0rem 2rem;
    text-align:  center;
  }

  &__logo {
    max-width: 100%;
  }

  &__nav {
    background:   @paletteMpkAccent;
    font-family:  @fontHeadline;
    text-align:   center;
    font-size:    0;
    margin-top:   1rem;
  }

  &__nav-list {
    &:extend(.reset-list-styles);
    margin:  0;
  }

  &__nav-link ,
  &__nav-item {
    margin:  0;
    display: inline-block;
  }

  &__nav-item {
    position: relative;
  }

  &__nav-link {
    font-size:        @sizeBase;
    padding:          0.45rem 0.5rem 0.2rem;
    color:            @paletteReverseInk;
    text-decoration:  none;

    .targeted({
      background:  @paletteReverseInk;
      color:       @paletteMpkAccent;

    });
  }

  &__nav-sub {
    &:extend(.reset-list-styles);
    z-index:     @layer-float;

    background:  shade(@paletteMpkAccent, 10%);
    display:     none;
    position:    absolute;
    top:         100%;
    left:        0;
  }

  &__nav-sub-item {
    display:      block;
    line-height:  1;
  }

  &__nav-sub-link {
    padding:      0.45rem 0.5rem 0.45rem;
    display:     block;
    white-space:  nowrap;

    font-size:        @sizeBase;
    text-decoration:  none;
    color:            @paletteReverseInk;

    .targeted({
      background:  @paletteReverseInk;
      color:       @paletteMpkAccent;
    });
  }

  &__nav-item:hover &__nav-sub {
    display: block;
  }

  .from(360, legacy, {
    &__title {
      width:        30%;
      margin-left:  1rem;
    }
  });

  .from(800, legacy, {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__nav {
      position:  absolute;
      right:     0;
      bottom:    2rem;
    }
  });
  

}

.content {
	display: block;
	padding: 0.5rem;
	color: @paletteMpkDarkBase;

	.from(@mediaSecondaryMenuBreak, {
		float: left;
		width: 60%;
		margin-bottom: 2rem;

		&--fullWidth {
			width: 100%;
			float: none;
		}
		&--shortWidth {
			width: 30%;
		}
	})
}


.editedContent\* {
	p, ul, ol {
		margin:       0 0 1rem;
		font-size:    @sizeContent;
    line-height:  @sizeContentLineHeight;
	}

	h3 {
		margin: 0;
		font-size: @sizeSubHeading;
	}
}
